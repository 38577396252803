<template>
  <GridRow class="four-teaser-row">
    <div v-for="article in box.relationships.articles" :key="article.id" class="one-fourth-teaser">
      <OpinionTeaser v-if="article.attributes.textType === 'Meinung'" :article="article" />
      <DefaultTeaser v-else :article="article" :lazy="lazy" />
    </div>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import DefaultTeaser from '@/components/ui/teaser/default-teaser'
import OpinionTeaser from '@/components/ui/teaser/opinion-teaser'

export default {
  components: {
    GridRow,
    DefaultTeaser,
    OpinionTeaser,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';
.four-teaser-row {
  @media all and (min-width: $screen-width-767) {
    padding: 0 14px 20px 14px;
  }

  @media all and (min-width: 1200px) {
    padding: 0 0 20px 0;
  }

  .one-fourth-teaser {
    border-bottom: 1px solid $color-light-grey;
    padding: 20px 0;

    @media all and (min-width: $screen-width-767) {
      padding: 0 14px;
      border-bottom: none;
      border-right: 1px solid $color-light-grey;
      flex-basis: calc(25% + 7px);
    }

    &:nth-child(1) {
      flex-basis: calc(25% - 7px);
      padding-left: 0;
    }

    &:nth-child(4) {
      flex-basis: calc(25% - 7px);
      padding-right: 0;
      border-right: none;
    }

    .default-teaser,
    .opinion-teaser {
      width: 100%;
    }

    @media all and (min-width: $screen-width-767) {
      .default-teaser__footer {
        padding-right: 0;
      }
    }
  }
}
</style>
