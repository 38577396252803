<template>
  <div :class="[darkmode ? 'sharebar--darkmode' : '', 'sharebar noprint']">
    <a
      v-if="!shareOn.length || shareOn.includes('facebook')"
      :href="'https://www.facebook.com/sharer/sharer.php?u=' + baseUrl + '&t=' + encodedShareTitle"
      title="auf Facebook teilen"
      target="_blank"
      @click="trackingEventsPush('facebook')"
    >
      <IconFacebook />
    </a>
    <a
      v-if="!shareOn.length || shareOn.includes('twitter')"
      :href="'https://twitter.com/share?url=' + baseUrl + '&text=' + encodedShareTitle + '&via=diefurche&lang=de'"
      data-via="diefurche"
      data-lang="de"
      title="auf Twitter teilen"
      target="_blank"
      @click="trackingEventsPush('twitter')"
    >
      <IconX />
    </a>
    <a
      v-if="!shareOn.length || shareOn.includes('whatsapp')"
      :href="'whatsapp://send?text=' + encodedShareTitle + '%20' + baseUrl"
      data-mcs-share="whatsapp"
      class="sharebar__icon-whatsapp"
      title="auf Whatsapp teilen"
      @click="trackingEventsPush('whatsapp')"
    >
      <IconWhatsapp width="18" height="18" />
    </a>
    <a
      v-if="!shareOn.length || shareOn.includes('mailto')"
      :href="mailtoLink"
      title="per Mail teilen"
      @click="trackingEventsPush('email')"
    >
      <IconMail />
    </a>
    <a v-if="showPrintButton" title="Drucken" @click="printPage">
      <IconPrint />
    </a>
    <!-- <a @click="createBookmark">
      <icon-bookmark />
    </a> -->
  </div>
</template>

<script>
import IconFacebook from '@/components/ui/icons/icon-facebook.svg'
import IconX from '@/components/ui/icons/icon-x.svg'
import IconMail from '@/components/ui/icons/icon-mail.svg'
import IconPrint from '@/components/ui/icons/icon-print.svg'
// import IconBookmark from '@/components/ui/icons/icon-bookmark.svg'
import IconWhatsapp from '@/components/ui/icons/icon-whatsapp.svg'

export default {
  components: {
    IconFacebook,
    IconX,
    IconMail,
    IconPrint,
    // IconBookmark,
    IconWhatsapp,
  },
  props: {
    shareTitle: {
      type: String,
      required: true,
    },
    darkmode: {
      type: Boolean,
      required: false,
    },
    shareOn: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    showPrintButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      baseUrl: null,
    }
  },
  computed: {
    encodedShareTitle() {
      return encodeURIComponent(this.shareTitle).replace(/%20/g, '+')
    },
    mailtoLink() {
      return `mailto:?body=${this.baseUrl}&subject=${this.shareTitle}`
    },
  },
  created() {
    this.baseUrl = 'https://www.furche.at' + this.$route.fullPath
  },
  methods: {
    trackingEventsPush(selectedShareMethod) {
      const eventName = 'Share'

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'mat' + eventName,
        shareMethod: selectedShareMethod,
      })
    },
    printPage() {
      window.print()
      this.trackingEventsPush('print')
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.sharebar {
  width: 100%;

  a {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-light-grey;
    width: 44px;
    height: 44px;
    margin: 0 5px;
    cursor: pointer;
  }

  a.sharebar__icon-whatsapp {
    @media all and (min-width: $screen-width-767) {
      display: none;
    }
  }
  path {
    fill: $color-text;
  }
  &--darkmode {
    a {
      background-color: rgba(255, 255, 255, 0.2);
      border: none;
      margin: 0 7px;
      transition: background-color 0.3s;
      &:first-of-type {
        margin-left: 0;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
    path {
      fill: $color-white;
    }
  }
}
</style>
