<template>
  <GridRow v-if="box.relationships.articles[0].type === 'Dossier'" class="herogrid dossier-box">
    <template v-for="article in box.relationships.articles">
      <HeroTeaser :key="article.id" :article="article" :dossier="true" :lazy="false" />
    </template>
  </GridRow>
  <GridRow v-else class="herogrid">
    <HeroTeaser v-for="article in box.relationships.articles" :key="article.id" :article="article" :lazy="false" />
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import HeroTeaser from '@/components/ui/teaser/hero'

export default {
  components: {
    GridRow,
    HeroTeaser,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
}
</script>
