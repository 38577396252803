<template>
  <NuxtLink :to="route">
    <slot>
      {{ channels | getChannelName($config) }}
    </slot>
  </NuxtLink>
</template>

<script>
export default {
  props: {
    channels: {
      type: Array,
      required: true,
    },
  },
  computed: {
    channelSlugs() {
      return this.$parseChannelSlugs(this.channels, this.$config).slice(0, -1)
    },
    route() {
      return {
        name: 'channel-canonical',
        params: {
          id: this.channels[0].id,
          channelSlugs: this.channelSlugs,
          slug: this.$slugify(this.channels[0].attributes.slug, { lower: true }),
        },
      }
    },
  },
}
</script>
