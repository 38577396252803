<script>
export default {
  props: {
    tag: {
      default: 'div',
      required: false,
      type: String,
    },
  },
  render(createElement) {
    return createElement(
      this.tag,
      {
        class: 'default-layout',
      },
      this.$slots.default
    )
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars.scss';

.default-layout {
  max-width: $page-max-width;
  min-width: $page-min-width;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
</style>
