<template>
  <p :class="`leadtext ${modifier}`">
    <slot />
  </p>
</template>

<script>
export default {
  props: {
    modifier: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.leadtext {
  margin-bottom: 1rem;
  color: $color-black;

  &--section {
    font-family: $georgia;
  }

  &--teaser {
    font-family: $georgia;
  }

  &--article-header {
    font-size: 18px;
  }
}
</style>
