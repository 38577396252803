<template>
  <div v-if="adsEnabled && showAdPosition" class="billboard" :id="desktopId"></div>
</template>

<script>
export default {
  props: {
    desktopId: {
      type: String,
      required: true,
    },
  },
  computed: {
    adsEnabled() {
      return this.$config.adsEnabled
    },
    noAdPositions() {
      return this.$store.getters['cope/noAdPositions']
    },
    showAdPosition() {
      return !this.noAdPositions.map((element) => element.formatId).includes(this.desktopId.replace('sas_', ''))
    },
  },
}
</script>
