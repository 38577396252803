<template>
  <GridRow v-if="box.relationships.articles[0].type === 'Dossier'" class="herogrid dossier-box">
    <template v-for="article in box.relationships.articles">
      <HeroTeaser :key="article.id" :article="article" :dossier="true" :lazy="false" />
    </template>
  </GridRow>
  <div v-else>
    <GridRow class="herogrid">
      <HeroTeaser v-for="article in box.relationships.articles" :key="article.id" :article="article" :lazy="false" />
    </GridRow>
    <GridRow class="hero-billboard">
      <BillBoard desktop-id="sas_23170" />
    </GridRow>
  </div>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import HeroTeaser from '@/components/ui/teaser/hero'
import BillBoard from '@/components/ads/billboard'

export default {
  components: {
    GridRow,
    HeroTeaser,
    BillBoard,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

.hero-billboard {
  display: none !important;

  @media screen and (min-width: 990px) {
    display: flex !important;
    justify-content: flex-start !important;
  }
}
</style>
