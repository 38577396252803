// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/svg/book-suggestion.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".books-and-film-box{color:#fff;display:flex;flex-direction:column;margin:0 auto 20px;overflow:hidden}@media screen and (min-width:767px){.books-and-film-box{flex-direction:row;max-width:1170px;width:100%}}.books-and-film-box .white-circle{background-color:#fff;border-radius:40px;display:none;height:80px;margin:0 auto 10px;width:80px}@media screen and (min-width:767px){.books-and-film-box .white-circle{align-items:center;display:flex;justify-content:center;margin:auto 40px auto 20px}}.books-and-film-box .teaser{display:flex;flex-direction:column;margin-bottom:20px;padding:37px 20px}@media screen and (min-width:767px){.books-and-film-box .teaser{flex-direction:row;margin-bottom:0;max-width:calc(50% - 15px);width:100%}}.books-and-film-box h2{margin:0}.books-and-film-box .teaser-text p{font-family:Georgia,serif;font-size:1rem;letter-spacing:-.01438rem;line-height:1.375rem;margin-bottom:20px}.books-and-film-box .film-teaser{background-color:#0c8696}@media screen and (min-width:767px){.books-and-film-box .film-teaser{margin-right:30px}}.books-and-film-box .book-teaser{background-color:#93141a}@media screen and (min-width:767px){.books-and-film-box .book-teaser{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:100% 100%;background-repeat:no-repeat}}.books-and-film-box .link-button{background-color:#fff;color:#0c8696;padding:10px}.books-and-film-box .link-button:nth-child(2n){color:#93141a}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
