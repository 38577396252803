<template>
  <GridRow v-if="box.relationships.articles[0].type === 'Dossier'" class="herogrid dossier-box">
    <template v-for="(article, index) in box.relationships.articles">
      <HeroTeaser :key="article.id" :article="article" :dossier="true" />
      <div :key="index" class="dossier-box__teaser grid-row">
        <template v-for="relatedArticle in article.relationships.articles">
          <OpinionTeaser
            v-if="relatedArticle.attributes.textType === 'Meinung'"
            :key="relatedArticle.id"
            :article="relatedArticle"
            class="one-fourth-teaser"
          />
          <DefaultTeaser v-else :key="relatedArticle.id" :article="relatedArticle" class="one-fourth-teaser" />
        </template>
      </div>
    </template>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import HeroTeaser from '@/components/ui/teaser/hero'
import DefaultTeaser from '@/components/ui/teaser/default-teaser'
import OpinionTeaser from '@/components/ui/teaser/opinion-teaser'

export default {
  components: {
    GridRow,
    HeroTeaser,
    DefaultTeaser,
    OpinionTeaser,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style lang="scss">
@import '../../../../css/import/vars';
.dossier-box {
  &.grid-row {
    flex-direction: column;
    border-bottom: none;
  }
  &__teaser {
    &.grid-row {
      border-bottom: none;
      @media screen and (min-width: $screen-width-767) {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .default-teaser {
          margin-bottom: 40px;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            bottom: -20px;
            left: 0;
            width: 100%;
            height: 1px;
            background: $color-light-grey;
          }

          &:first-child,
          &:nth-child(5n) {
            &:after {
              content: '';
              position: absolute;
              bottom: -20px;
              left: 13px;
              width: calc(100% - 13px);
              height: 1px;
              background: $color-light-grey;
            }
          }

          &:nth-child(4n) {
            &:after {
              content: '';
              position: absolute;
              bottom: -20px;
              left: 0;
              right: 13px !important;
              width: calc(100% - 13px);
              height: 1px;
              background: $color-light-grey;
            }
          }

          &:last-of-type {
            &:after {
              content: '';
              position: absolute;
              bottom: -20px;
              left: 0;
              right: 13px;
              width: calc(100% - 13px);
              height: 1px;
              background: $color-light-grey;
            }
          }
        }
      }
    }

    .one-fourth-teaser {
      border-bottom: 1px solid $color-light-grey;
      padding-bottom: 20px;
      margin-bottom: 20px;

      @media all and (min-width: $screen-width-767) {
        padding-left: 13px;
        padding-right: 13px;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
        border-right: 1px solid $color-light-grey;

        &:nth-child(4n),
        &:last-of-type {
          border-right: 1px solid $color-white;
        }
      }
    }
  }
  article:first-of-type {
    margin-bottom: 20px;
  }
}
</style>
