<template>
  <div v-if="starAuthors.length > 0" class="star-authors-box">
    <StarAuthorsSlider :id="box.id" :star-authors="starAuthors" />
  </div>
</template>

<script>
import StarAuthorsSlider from '@/components/ui/slider/star-authors-slider'

export default {
  components: {
    StarAuthorsSlider,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
    starAuthors: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

.star-authors-box {
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
  margin: 0 0 0 20px;
  width: calc(100% - 20px);

  @media all and (min-width: $screen-width-767) {
    width: calc(100% - 26px);
    flex-direction: row;
    justify-content: center;
    max-width: $channel-max-width;
    margin: 0 13px 20px 13px;
    border-bottom: 1px solid $color-light-grey;
  }

  @media all and (min-width: $screen-width-1200) {
    margin: 0 auto 20px auto;
    width: 100%;
  }

  &:last-child {
    border-bottom: none;
  }
}
</style>
