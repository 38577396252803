<template>
  <GridRow>
    <div class="five-teaser-row">
      <div class="five-teaser-row__default">
        <div v-for="article in smallTeasers" :key="article.id" class="five-teaser-row__smallteaser">
          <OpinionTeaser v-if="article.attributes.textType === 'Meinung'" :article="article" :medium-teaser="true" />
          <FloatingImageTeaser v-else :article="article" :lazy="lazy" />
        </div>
      </div>
      <div class="five-teaser-row__bgteaser">
        <BackgroundTeaser :article="bigTeaser" class="five-teaser-row__bigteaser" />
      </div>
    </div>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import FloatingImageTeaser from '@/components/ui/teaser/floating-image-teaser'
import OpinionTeaser from '@/components/ui/teaser/opinion-teaser'
import BackgroundTeaser from '@/components/ui/teaser/background-teaser'

export default {
  components: {
    GridRow,
    FloatingImageTeaser,
    OpinionTeaser,
    BackgroundTeaser,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    smallTeasers() {
      return this.box.relationships.articles.slice(0, 4)
    },
    bigTeaser() {
      return this.box.relationships.articles.slice(-1)[0]
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

.five-teaser-row {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media all and (min-width: $screen-width-767) {
    padding: 0 14px;
  }

  @media all and (min-width: $screen-width-1023) {
    flex-direction: row;
  }

  @media all and (min-width: 1200px) {
    padding: 0;
  }

  &__default {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media all and (min-width: $screen-width-767) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media all and (min-width: $screen-width-1023) {
      flex-basis: 50%;
      margin: 0;
    }
  }

  &__smallteaser {
    position: relative;
    padding: 20px 0;

    &:nth-child(1) {
      padding-top: 0;
    }

    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      height: 1px;
      width: calc(100%);
      background-color: $color-light-grey;
    }

    @media all and (min-width: $screen-width-767) {
      padding: 20px 14px;

      &::after {
        width: calc(100% - 14px);
      }
    }

    &:nth-child(odd) {
      flex-basis: 50%;

      @media all and (min-width: $screen-width-767) {
        padding-left: 0;
        border-right: 1px solid $color-light-grey;

        &::after {
          left: 0;
        }
      }

      @media all and (min-width: $screen-width-1023) {
        flex-basis: calc(50% - 7px);
      }
    }

    &:nth-child(even) {
      flex-basis: 50%;

      @media all and (min-width: $screen-width-767) {
        padding-right: 0;

        &::after {
          right: 0;
        }
      }

      @media all and (min-width: $screen-width-1023) {
        padding-right: 14px;
        flex-basis: calc(50% + 7px);
        border-right: 1px solid $color-light-grey;

        &::after {
          right: 14px;
          width: calc(100% - 28px);
        }
      }
    }

    // first two teasers
    &:nth-child(-n + 2) {
      @media all and (min-width: $screen-width-767) {
        padding-top: 0;
        padding-bottom: 18px;
      }
    }

    // last two teasers
    &:nth-child(n + 3) {
      @media all and (min-width: $screen-width-767) {
        padding-top: 10px;
      }

      @media all and (min-width: $screen-width-1023) {
        padding-bottom: 0;

        &::after {
          display: none;
        }
      }
    }

    .floating-image-teaser,
    .opinion-teaser {
      height: 100%;
    }
  }

  &__bgteaser {
    max-width: 570px;
    margin: 0 auto;
    display: flex;

    @media all and (min-width: $screen-width-1023) {
      margin: 0;
      max-width: 100%;
      flex: 1 1 50%;
    }
  }

  &__bigteaser {
    @media all and (min-width: $screen-width-767) {
      padding-left: 14px;
      padding-bottom: 0;
    }

    .responsive-image {
      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
</style>
