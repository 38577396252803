<template>
  <div class="author">
    <AuthorLink :author="author" class="author__link">
      <SpunqImage v-if="needsImage" :image="authorImage" :width="80" :height="80" mode="crop" class="author__image" />
      <div>
        <h2 class="author__name">
          {{ authorName }}
        </h2>
        <p v-if="!nameOnly && !positionOnly && author.attributes.profileText" class="author__profile-text">
          {{ author.attributes.profileText }}
        </p>
        <p v-else-if="positionOnly" class="author__position">
          {{ author.attributes.position }}
        </p>
      </div>
    </AuthorLink>
    <ButtonFollowTwitter
      v-if="!nameOnly && author.attributes.twitterUsername"
      :twitter-username="author.attributes.twitterUsername"
    />
  </div>
</template>

<script>
import SpunqImage from '@/components/ui/image/spunq-image'
import AuthorLink from '@/components/ui/author/link'
import ButtonFollowTwitter from '@/components/ui/socialmedia/button-follow-twitter'

export default {
  components: {
    SpunqImage,
    AuthorLink,
    ButtonFollowTwitter,
  },
  props: {
    author: {
      type: Object,
      required: true,
    },
    nameOnly: {
      type: Boolean,
      default: false,
    },
    teaserImage: {
      type: Boolean,
      default: false,
    },
    positionOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    authorName() {
      const { firstName, lastName } = this.author.attributes
      // removes double whitespaces in case some name parts are undefined
      return `${firstName || ''} ${lastName || ''}`.replace(/  +/g, ' ')
    },
    needsImage() {
      if (this.nameOnly) {
        return false
      }

      if (this.teaserImage && this.author.attributes.teaserImage) {
        return true
      }

      return !this.teaserImage && this.author.attributes.image
    },
    authorImage() {
      if (this.teaserImage) {
        return this.author.attributes.teaserImage
      }

      return this.author.attributes.image
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.author {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__link {
    display: flex;
    align-items: center;
    color: $color-text;
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    position: relative;
    z-index: 1;
  }

  &__image {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 20px;
    flex-shrink: 0;
  }

  &__profile-text {
    color: $color-line-gray;
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    line-height: 17px;
    font-size: 13px;
    margin-right: 20px;
  }

  &__position {
    color: $color-red;
    font-size: 13px;
    font-weight: 300;
  }

  &__name {
    font-size: 16px;
    margin-bottom: 0;
  }
}
</style>
