<template>
  <div class="promotion-box__two-boxes" v-if="isSecondBoxDefined">
    <PromotionComponent
      class="promotion-box__two-boxes__first"
      :title="box.attributes.templateOptions.title1"
      :description="box.attributes.templateOptions.description1"
      :image="box.attributes.templateOptions.image1"
      :backgroundColor="box.attributes.templateOptions?.backgroundColor1"
      :buttonName="box.attributes.templateOptions.buttonName1"
      :buttonLink="box.attributes.templateOptions.buttonLink1"
    />
    <PromotionComponent
      class="promotion-box__two-boxes__second"
      :title="box.attributes.templateOptions.title2"
      :description="box.attributes.templateOptions.description2"
      :image="box.attributes.templateOptions.image2"
      :backgroundColor="box.attributes.templateOptions?.backgroundColor2"
      :buttonName="box.attributes.templateOptions.buttonName2"
      :buttonLink="box.attributes.templateOptions.buttonLink2"
    />
  </div>
  <PromotionComponent
    v-else
    class="promotion-box__one-box"
    :title="box.attributes.templateOptions.title1"
    :description="box.attributes.templateOptions.description1"
    :image="box.attributes.templateOptions.image1"
    :backgroundColor="box.attributes.templateOptions?.backgroundColor1"
    :buttonName="box.attributes.templateOptions.buttonName1"
    :buttonLink="box.attributes.templateOptions.buttonLink1"
  />
</template>

<script>
import PromotionComponent from '@/components/channel/boxes/box/promotion-component'
export default {
  components: {
    PromotionComponent,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isSecondBoxDefined() {
      return (
        this.box.attributes.templateOptions.buttonName2.trim() || this.box.attributes.templateOptions.buttonLink2.trim()
      )
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';
.promotion-box {
  &__two-boxes {
    margin: auto;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $screen-width-767) {
      max-width: 1170px;
      flex-direction: row;
      gap: 30px;
      margin-bottom: 2rem;

      &__first {
        width: 100%;
      }

      &__second {
        width: 100%;
      }
    }
  }
  &__one-box {
    @media screen and (min-width: $screen-width-767) {
      max-width: 1170px;
      margin: 0 auto 3rem auto;
    }
  }
}
</style>
